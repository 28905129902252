export class DragToScroll {
    constructor(element) {
        this.block = element;
        this.mouseDownHandler = this.mouseDownHandler.bind(this)
        this.mouseMoveHandler = this.mouseMoveHandler.bind(this)
        this.mouseUpHandler = this.mouseUpHandler.bind(this)
        this.pos = {left: 0, x: 0};
        if (this.block.querySelector('table').clientWidth > this.block.offsetWidth) {
            this.block.style.cursor = 'grab';
        }
        this.block.addEventListener('mousedown', this.mouseDownHandler);
    }

    mouseMoveHandler(e) {
        const dx = e.clientX - this.pos.x;
        this.block.scrollLeft = this.pos.left - dx;
    };

    mouseUpHandler() {
        document.removeEventListener('mousemove', this.mouseMoveHandler);
        document.removeEventListener('mouseup', this.mouseUpHandler);

        this.block.style.cursor = 'grab';
        this.block.style.removeProperty('user-select');
    };

    mouseDownHandler(e) {
        if (this.block.querySelector('table').clientWidth <= this.block.offsetWidth) return;
        this.block.style.cursor = 'grabbing';
        this.block.style.userSelect = 'none';
        this.pos = {
            left: this.block.scrollLeft,
            x: e.clientX,
        };

        document.addEventListener('mousemove', this.mouseMoveHandler);
        document.addEventListener('mouseup', this.mouseUpHandler);
    };

}
