const calc = document.querySelector('.calc_roi');
const secondText = document.querySelector('.calc-second_text');
const calcResult = document.querySelector('.calc_result');
const calcSelected = document.querySelector('.calc_roi-selected');
const reload = document.querySelector('.calc_result-button');
const resultSelected = document.querySelector('.calc_result-selected');
const resultMoney = document.querySelector('.calc_result-income_item-value.money');
const resultMonth = document.querySelector('.calc_result-income_item-value.month');
const resultSum = document.querySelector('.calc_result-income_item-value.sum');
const resultBar = document.querySelector('.calc_result-income_item-bar.month');
let result = {};

const error = {
    sum: 'Введите сумму',
    time: 'Выберите дату',
    select: 'Выберите фонд(ы)'
}

export default function () {
    if (calc) {
        const number = calc.querySelector('#number');
        const time = calc.querySelector('#time');
        const timeArrow = calc.querySelector('.calc_roi-time_wrapper_arrow');
        time.addEventListener('click', () => {
            timeArrow.classList.toggle('active');
        })
        number.addEventListener('keyup', numberFunc)
        number.addEventListener('blur', numberFunc)

        function numberFunc() {
            if (parseInt(number.value) > 0) {
                number.parentElement.dataset.error = '';
                number.classList.add('checked');
                number.classList.remove('error');
            } else {
                number.parentElement.dataset.error = error.sum;
                number.classList.add('error');
                number.classList.remove('checked');
            }
        }

        time.addEventListener('change', () => {
            if (time.value !== 'default') {
                time.parentElement.dataset.error = '';
                time.classList.add('checked');
                time.classList.remove('error');
            } else {
                time.parentElement.dataset.error = error.time;
                time.classList.add('error');
                time.classList.remove('checked');
            }
        })

        calc.addEventListener('submit', e => {
            e.preventDefault();
            result = {};
            const checked = calc.querySelectorAll('[name="roi[]"]')
            let sum = 0.0;
            let counter = 0;
            let selected = [];
            let validate = true;
            checked.forEach(e => {
                if (e.checked) {
                    sum += parseFloat(e.value);
                    counter++;
                    selected.push(e);
                }
            })

            if (!check(counter !== 0, calcSelected, error.select)) {
                validate = false;
            }
            if (!check(number.value > 0, number.parentElement, error.sum)) {
                validate = false;
            }
            if (!check(time.value !== 'default', time.parentElement, error.time)) {
                validate = false;
            }

            if (validate) {
                result.percent = (sum / counter / 12 * time.value).toFixed(2);
                sum = ((sum / counter / 12 / 100) * number.value * time.value).toFixed(2);
                result.sum = sum;
                result.money = number.value;
                result.time = time.value;
                result.selected = selected;
                calc.style.display = 'none';
                secondText.style.display = 'none';
                calcResult.style.display = 'flex';
                render(result);
            }
        });

        function check(bool, item, message) {
            if (bool) {
                item.dataset.error = '';
                return true;
            } else {
                item.dataset.error = message;
                return false;
            }
        }

        function render(result) {
            resultSelected.innerHTML = '';
            result.selected.forEach(e => {
                let html = document.createElement('div');
                html.classList.add('calc_result-selected_item');
                html.innerHTML = e.dataset.name;
                resultSelected.appendChild(html);
            })
            resultMoney.innerHTML = '$' + result.money;
            let monthText = result.time === '3' ? textForMonth : textForMonths;
            console.log(monthText)
            resultMonth.innerHTML = result.time + ' ' + monthText;
            resultBar.value = result.time;
            resultSum.innerHTML = '$' + result.sum + ' (' + result.percent + '%)';
        }

        reload.addEventListener('click', () => {
            calc.style.display = 'flex';
            secondText.style.display = 'block';
            calcResult.style.display = 'none';
        })

    }

}

