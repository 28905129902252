import {DragToScroll} from './modules/dragToScroll'
import roiCalc from "./modules/roiCalc";
import temp from "./modules/temp";

roiCalc();

const tableWrappers = document.querySelectorAll('.esc-table-scroll');
tableWrappers.forEach(wrapper => {
    new DragToScroll(wrapper);
})

// START selector functional
const tabsWrapper = document.querySelectorAll('.all-tables-wrapper');
tabsWrapper.forEach(wrapper => {
    const selectorPlatform = wrapper.querySelector('.esc-table-select-body#platforms');
    const selectorInstruments = wrapper.querySelector('.esc-table-select-body#instruments');
    const tables = wrapper.querySelectorAll('table.esc-table');
    changeSelector(tables, selectorPlatform, selectorInstruments);
    selectorInstruments?.addEventListener('change', () => {
        changeSelector(tables, selectorPlatform, selectorInstruments);
    })
    selectorPlatform?.addEventListener('change', () => {
        changeSelector(tables, selectorPlatform, selectorInstruments);
    })
})

function changeSelector(tables, selectorPlatform, selectorInstruments) {
    tables.forEach(table => {
        if (table.dataset.type === selectorInstruments?.value + '_' + selectorPlatform?.value || table.dataset.type === selectorInstruments.value) {
            table.parentNode.parentNode.style.display = 'block';
        } else {
            table.parentNode.parentNode.style.display = 'none';
        }
    })
}

// END selector functional


const search = document.querySelector('.esc-search')
const brands = document.querySelectorAll('tr[data-code]')
const drop = document.querySelector('.esc-select-region')
const timeButtons = document.querySelectorAll('.esc-tab')
let time = 'ec_all';

if (search && drop) {
    search.addEventListener('input', searchESC)
    drop.addEventListener('input', searchESC)
    timeButtons.forEach(button => {
        button.addEventListener('click', function () {
            removeActive(timeButtons)
            button.classList.add('active')
            time = button.dataset.type;
            searchESC();
        });
    })
}

function removeActive(items) {
    items.forEach(item => {
        item.classList.remove('active')
    })
}

function searchESC() {
    brands.forEach(brand => {
        let checkerSearch = true;
        let checkerSelector = true;
        let checkerTime = true;
        if (search.value) {
            if (!(brand.dataset.name?.toLowerCase().includes(search.value) || brand.dataset.code?.toLowerCase().includes(search.value))) {
                checkerSearch = false;
            }
        }
        if (drop.value) {
            if (brand.dataset.country !== drop.value) {
                checkerSelector = false;
            }
        }
        if (brand.dataset.time && time === 'this_week') {
            let now = new Date();
            let startOfDay = new Date(now.getFullYear(), now.getMonth(), now.getDate()) / 1000;

            let myDate = brand.dataset.time;
            myDate = myDate.split(".");
            let brandTime = new Date(myDate[2], myDate[1] - 1, myDate[0] - 7).getTime() / 1000;
            let brandTime2 = new Date(myDate[2], myDate[1] - 1, myDate[0]).getTime() / 1000;
            if (brandTime > startOfDay || brandTime2 < startOfDay) {
                checkerTime = false
            }
        }
        if (checkerSearch && checkerSelector && checkerTime) {
            brand.style.display = "table-row"
        } else {
            brand.style.display = "none"
        }
    });


}

temp();


